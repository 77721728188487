// wazers
/*
const Config = {
    url:'https://admin.wazers.fr/api',
    apikey:'732BB8FE3AB4D7595B2FB1AB7B2AB',
    content_type:'Content-type',
    img_url:'https://admin.wazers.fr/',
    id_partnair:"1",
    Copyright_name:"Wazers",
    Copyright_url:"https://wazers.fr/",
    activecompany:false,
    firebase:false,
    courrancy:"€",
    pages:true,
    loginWithPhon:true,
    aviationstack:"75250a9486a0fcfaa0cb185a334e2609",
    bilalid:58049,
    conterMap:{
        lat: 48.87297020957097,
        lng:2.3487516135686106
    },
    isActiveAcount:false,
    multiCart:false,
    activeVolPlanet:true,
    activeVoltrain:true
};
export default Config;
*/
// mycheffur
const Config = {
    url:'https://admin.zencab.fr/api',
    apikey:'8BA193669B12748F29F251FC1477F',
    content_type:'Content-type',
    img_url:'https://admin.zencab.fr/',
    id_partnair:"1",
    Copyright_name:"Zencab",
    Copyright_url:"https://zencab.fr/",
    googkeMapUrl:"AIzaSyBr7ZSYu1M-vGGApJ4S0MqMHwz7h3XcslU",
    Region:"FR",
    activecompany:false,
    firebase:false,
    courrancy:"€",
    pages:true,
    loginWithPhon:true,
    aviationstack:"75250a9486a0fcfaa0cb185a334e2609",
    bilalid:58049,
    conterMap:{
        lat:49.01759615138898, 
        lng: 2.337344479555409
    },
    isActiveAcount:true,
    multiCart:false,
    activeVolPlanet:true,
    activeVoltrain:false,
    phoneContact:"+33.7.61.62.80.80",
    appUrlAnd:"https://play.google.com/store/apps/details?id=com.zencab.app&hl=fr",
    appUrlIos:"https://apps.apple.com/fr/app/zencab/id1591248383"
};
export default Config;
